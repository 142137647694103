<template>
  <van-form @submit="onSubmit">
    <van-field
      v-model="buyer.name"
      name="name"
      label="你的昵称"
      placeholder="你的昵称"
    />
    <van-field name="uploader" label="你的头像">
      <template #input>
        <van-uploader
          v-model="uploader"
          :max-count="1"
          :max-size="10 * 1024 * 1024"
          :before-read="beforeRead"
          :after-read="afterRead"
        />
      </template>
    </van-field>
    <div style="margin: 16px;">
      <van-button round block type="primary" native-type="submit">
        提交
      </van-button>
    </div>
  </van-form>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import { upload } from '@/util/util'
import { Toast } from 'vant'

export default {
  components: {
  },
  setup () {
    const post = inject('post')
    // const useRouter = inject('useRouter')
    const Cookies = inject('Cookies')
    const state = reactive({
      buyerId: Cookies.get('buyerId'),
      buyer: {},
      uploader: [],
      qiniu: {}
    })
    const onSubmit = (values) => {
      post('/buyer.update', {
        buyerId: state.buyerId,
        name: values.name,
        avatar: values.uploader[0] ? values.uploader[0].url : ''
      }).then(res => {
        if (res.code === 0) {
          Toast.success()
        } else {
          Toast(res.msg)
        }
      })
    }
    const beforeRead = (file) => {
      return true
    }
    const afterRead = (file) => {
      upload({
        fileBlob: file.file,
        token: state.qiniu.token,
        domain: state.qiniu.domain
      }).then(res => {
        file.url = res.img
        console.log(res.img)
      })
    }
    const init = () => {
      post('/buyer.get', {
        buyerId: state.buyerId
      }).then(res => {
        state.buyer = res.data
        if (res.data.avatar !== '') {
          state.uploader.push({
            url: res.data.avatar,
            isImage: true
          })
        }
      })
      post('/util.getQiniuToken').then(res => {
        state.qiniu = res.data
      })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      beforeRead,
      afterRead,
      onSubmit
    }
  }
}
</script>

<style scoped>
</style>
